export default function createWorker() {
  const workerBlob = new Blob(
    [
      `
      let intervalId = null;
      let elapsedTime = 0;
      
      function startTimer(interval) {
        intervalId = setInterval(() => {
          elapsedTime++;
          postMessage(elapsedTime);
        }, interval);
      }
      
      function stopTimer() {
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      }
      
      onmessage = (event) => {
        const { action, interval } = event.data;
        if (action === 'start') {
          startTimer(interval);
        } else if (action === 'stop') {
          stopTimer();
        }
      }
      `,
    ],
    { type: "application/javascript" }
  );

  const worker = new Worker(URL.createObjectURL(workerBlob));
  return worker;
}
