<template>
  <router-view v-if="showRouterView"></router-view>
  <Modal
    title=" "
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    footerHide
    :width="420"
    v-model="showModal"
    @on-visible-change="onModalChanged"
  >
    <XClockInDialog />
  </Modal>
</template>

<script>
import { h } from "vue";
import { Modal } from "view-ui-plus";
import "element-plus/es/components/notification/style/css";
import { ElNotification } from "element-plus";
import XClockInDialog from "@/components/XClockInDialog";
import { channelEvent, getPCClientVersion, userInfo } from "@/common/api";
import { syncData, initInexDB } from "@/common/syncUntil";
export default {
  name: "App",
  components: { XClockInDialog, Modal },
  data() {
    return {
      incrementDate: {},
      notification: null,
      showRouterView: false,
      isUpdating: false,
    };
  },
  computed: {
    showModal() {
      return this.$store.state.showClockDialog;
    },
  },
  async beforeCreate() {
    console.log("first load");
    userInfo()
      .then(async (info) => {
        if (info) {
          this.$store.commit("updateInfo", info);
          await initInexDB();
          await syncData();
        }
      })
      .finally(() => {
        this.showRouterView = true;
      });
  },
  created() {
    const theme = localStorage.getItem("theme-appearance") || "light";
    console.log(theme);
    const appElement = document.getElementById("app");
    if (appElement && theme === "dark") {
      appElement.style.backgroundColor = "#1b1b1f";
    }
    this.themeChange(theme);
  },
  async mounted() {
    if (process?.env?.IS_ELECTRON) {
      setTimeout(() => {
        this.getRemoteVersion();
      }, 5000);
      this.initShortcut();
      this.ipcRendererInit();
    }
  },
  methods: {
    onModalChanged(value) {
      this.$store.commit("updateShowDialog", value);
    },
    themeChange(type) {
      const x = 0;
      const y = 0;
      const endRadius = Math.hypot(
        Math.max(x, innerWidth - x),
        Math.max(y, innerHeight - y)
      );

      document.documentElement.style.setProperty("--x", x + "px");
      document.documentElement.style.setProperty("--y", y + "px");
      document.documentElement.style.setProperty("--r", endRadius + "px");

      // 判断浏览器是否支持document.startViewTransition
      // if (document.startViewTransition) {
      //   // 如果支持就使用document.startViewTransition方法
      //   document.startViewTransition(() => {
      //     this.classToggle(type);
      //   });
      // } else {
      //   // 如果不支持，就使用最原始的方式，切换主题
      //   this.classToggle(type);
      // }
      this.classToggle(type);
    },
    classToggle(type) {
      const htmlElement = document.querySelector("html");
      if (type === "dark") {
        htmlElement.classList.remove("light");
        htmlElement.classList.add(type);
      } else {
        htmlElement.classList.remove("dark");
        htmlElement.classList.add(type);
      }
    },
    initShortcut() {
      const shortKey = localStorage.getItem("shortKey") || "CommandOrControl+Y";
      channelEvent("shortKey", shortKey);
    },
    ipcRendererInit() {
      const { ipcRenderer } = require("electron");
      ipcRenderer.on("notice", (event, data) => {
        console.log("notice notice notice");
        if (!this.notification) {
          this.incrementDate = data;
          this.notice();
        }
      });
      ipcRenderer.on("updateNotice", () => {
        this.getRemoteVersion();
      });
    },
    handleLinkClick(event) {
      event.preventDefault();
      if (this.isUpdating) {
        return false;
      }
      this.isUpdating = true;
      document.getElementById("update-a").innerText = "更新中...";
      const data = JSON.parse(JSON.stringify(this.incrementDate));
      if (data.restart && process.platform === "win32") {
        this.notification.close();
        this.notification = null;
        ElNotification({
          type: "success",
          duration: 0,
          title: "版本更新中...",
          customClass: "custom-notification",
          message:
            " 如果更新失败，请复制此链接手动下载安装( https://file.yimutodo.com/downloads/windows/yimutodo.exe )",
        });
        const path = require("path");
        const dest = path.join(
          process.resourcesPath,
          "./app.asar.unpacked",
          "yimutodo.exe"
        );
        const url = "https://file.yimutodo.com/downloads/windows/yimutodo.exe";
        this.getRemoteApp(url, dest)
          .then(() => {
            this.$Message.success("下载成功，开始安装！");
            this.installExe(dest);
          })
          .catch((error) => {
            console.error("Download failed:", error);
          });
        return false;
      } else {
        channelEvent("increment", data);
      }
    },
    notice() {
      this.notification = ElNotification({
        type: "success",
        title: `发现新版本${this.incrementDate.version}~`,
        message: h("div", [
          h("p", `${this.incrementDate.message}`),
          h(
            "a",
            {
              href: "#",
              id: "update-a",
              style: {
                color: "#409EFF",
                display: "inline-block",
                float: "right",
                fontSize: "12px",
              },
              onClick: this.handleLinkClick,
            },
            "立即升级"
          ),
        ]),
        duration: 0,
        customClass: "custom-notification",
        dangerouslyUseHTMLString: false,
      });
    },
    onUpdateOk() {
      channelEvent("increment", JSON.parse(JSON.stringify(this.incrementDate)));
    },
    getRemoteVersion() {
      getPCClientVersion("PC_CLIENT_VERSION").then((res) => {
        const data = JSON.parse(res && res[0].value) || {};
        channelEvent("remoteVersion", data);
      });
    },
    async getRemoteApp(url, dest) {
      const fse = require("fs-extra");
      const https = require("https");
      return new Promise((resolve, reject) => {
        const file = fse.createWriteStream(dest);
        https
          .get(url, (response) => {
            response.pipe(file);
            file.on("finish", () => {
              file.close(resolve); // 完成后关闭文件
            });
          })
          .on("error", (err) => {
            fse.unlink(dest); // 删除文件
            reject(err);
          });
      });
    },
    installExe(exePath) {
      const { exec } = window.require("child_process");
      exec(`"${exePath}"`, (error, stdout, stderr) => {
        if (error) {
          console.log(error.message);
          return;
        }
        if (stderr) {
          console.log(stderr);
          return;
        }
        console.log(stdout);
      });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  height: 100%;
  background-color: transparent !important;
}
</style>
