import { h } from "snabbdom";
import { Boot } from "@wangeditor/editor";
import { DomEditor } from "@wangeditor/editor";
import { Editor, Transforms, Point } from "slate";
import MarkdownIt from "markdown-it";
const md = new MarkdownIt({
  linkify: true,
  typographer: true,
});
function addContentEditableToOuterTag(htmlContent) {
  return htmlContent.replace(
    /^<([a-zA-Z0-9]+)([^>]*)>/,
    (match, tag, attrs) => {
      return `<${tag} contentEditable="false"${attrs}>`;
    }
  );
}

function renderMarkdown(elemNode, children, editor) {
  const markdownText = elemNode.markdownText || "";
  const htmlContent = md.render(markdownText);
  const htmlRes = addContentEditableToOuterTag(htmlContent);
  const selected = DomEditor.isNodeSelected(editor, elemNode);
  const vnode = h(
    "div",
    {
      dataset: {
        selected: selected ? "true" : "",
      },
      on: {
        mousedown: (event) => {
          const divElement = event.currentTarget;
          const isSelected = divElement.dataset.selected === "true";
          divElement.dataset.selected = isSelected ? "" : "true";
          event.stopPropagation();
          event.preventDefault();
        },
      },
      innerHTML: htmlRes,
    },
    children
  );
  return vnode;
}

const renderElemsConf = {
  type: "markdown",
  renderElem: renderMarkdown,
};

function markdownToHtml(elem, childrenHtml) {
  return `<div data-w-e-type="markdown"">${childrenHtml}</div>`;
}

const elemsToHtmlConf = {
  type: "markdown",
  elemToHtml: markdownToHtml,
};

function parseMarkdownHtml(domElem) {
  const text = domElem.firstChild?.textContent;

  const myResume = {
    type: "markdown",
    children: [{ text: text || "" }],
  };

  return myResume;
}
const parseElemsHtmlConf = {
  selector: 'div[data-w-e-type="markdown"]',
  parseElemHtml: parseMarkdownHtml,
};

function insertBreakPlugin(editor) {
  const { insertBreak, insertText } = editor;
  const newEditor = editor;

  // 重写 insertBreak - 换行时插入 p
  newEditor.insertBreak = () => {
    const { selection } = newEditor;
    if (selection == null) return insertBreak();
    const [nodeEntry] = Editor.nodes(editor, {
      match: (n) => {
        return DomEditor.checkNodeType(n, "markdown");
      },
      universal: true,
    });
    if (!nodeEntry) return insertBreak();
    const elem = nodeEntry[0];
    const elemPath = DomEditor.findPath(editor, elem);
    const elemEndLocation = Editor.end(editor, elemPath);

    if (Point.equals(elemEndLocation, selection.focus)) {
      // const str = Node.string(elem);
      // if (str && str.slice(-1) === "\n") {
      //   // 节点的文本最后一个是 \n
      //   editor.deleteBackward("character"); // 删除最后一个 \n

      //   // 则插入一个 paragraph
      //   const p = { type: "paragraph", children: [{ text: "" }] };
      //   Transforms.insertNodes(newEditor, p, { mode: "highest" });
      //   return;
      // }
      const p = { type: "paragraph", children: [{ text: "" }] };
      Transforms.insertNodes(newEditor, p, { mode: "highest" });
    }
    insertText("\n");
  };
  return newEditor;
}

Boot.registerModule({
  renderElems: [renderElemsConf],
  elemsToHtml: [elemsToHtmlConf],
  parseElemsHtml: [parseElemsHtmlConf],
  editorPlugin: insertBreakPlugin,
});
