<template>
  <List class="group-menu" :split="false">
    <ListItem 
      size="small"
      v-for="value, key in data" 
      :key="key" 
      :class="{'menu-item': true, 'selected': key==selected}"
      @click="onItemClick(key)">
      <XIcon :icon-class="cellIcon(key)" style="margin-right: 8px; color: #9C9C9C;" v-show="key !== 'Pomodoro'"/>
      <Icon v-show="key === 'Pomodoro'" style="margin-right: 8px;" type="ios-clock" :size="16"/>
      {{ value }}
    </ListItem>
  </List>
</template>
<script>
export default {
  data() {
    return {
      data: {
        'quadrant': '四象限',
        'map': '任务地图',
        'Pomodoro': '专注管理'
      },
      selected: 'quadrant',
    }
  },
  mounted() {
    this.selected = this.$route.params.item;
  },
  methods: {
    cellIcon(key) {
      let map = {
        'quadrant': 'group',
        'map': 'location',
        'Pomodoro': 'clock'
      };
      return map[key] || 'view';
    },
    onItemClick(value) {
      this.$router.push('/manager/group/' + value);
    }
  },
  watch: {
    $route() {
      this.selected = this.$route.params.item;
    }
  }
}
</script>
<style lang="less">
  .group-menu {
    overflow: scroll;
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-1);
    flex: 1;
    padding: 16px 4px 16px 10px;
    li.menu-item {
      padding: 12px 8px;
      border-radius: 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
      &.selected,&:hover {
        background-color: var(--vp-c-bg-blue);
      }
    }
  }
</style>