<template>
  <Circle
    :size="600"
    :trail-width="0.3"
    :stroke-width="0.3"
    :percent="percent"
    trail-color="#d6d6d66b"
    stroke-color="#43a3fb"
  >
    <div class="demo-Circle-custom">
      <Button
        class="btn-list"
        v-show="!isFocus"
        :size="24"
        type="primary"
        @click="startCount(1)"
        ><Icon type="ios-arrow-back" style="color: #fff"></Icon
        >开始倒计时</Button
      >
      <TimePicker
        v-show="!isFocus"
        format="HH:mm:ss"
        placeholder="选择时间"
        :model-value="timeValue"
        @on-change="timeChange"
      />
      <Button
        class="btn-list"
        v-show="!isFocus"
        :size="24"
        type="primary"
        @click="startCount(0)"
        >开始正计时<Icon type="ios-arrow-forward" style="color: #fff"></Icon
      ></Button>
      <p style="font-size: 100px; font-weight: 1" v-show="isFocus">
        {{ countDown }}
      </p>
      <p>专注关联任务</p>
      <Tag
        :title="item.title"
        v-for="(item, index) in taskList"
        :key="item.taskId"
        :name="item.taskId"
        :closable="isPause || !isFocus"
        @on-close="handleClose"
        >{{ index + 1 }}.{{ item.subTitle }}</Tag
      >
      <Button
        v-show="isPause || !isFocus"
        icon="ios-add"
        type="success"
        size="small"
        @click="handleAdd"
        ghost
        >添加任务</Button
      >
      <div style="margin-top: 20px">
        <Button
          class="btn-list"
          v-show="isFocus && !isPause"
          :size="24"
          icon="md-pause"
          type="primary"
          @click="pauseCount"
          >暂停</Button
        >
        <Button
          class="btn-list"
          v-show="isFocus && isPause"
          :size="24"
          icon="md-arrow-dropright"
          type="primary"
          @click="continueCount"
          >继续</Button
        >
        <Button
          class="btn-list"
          v-show="isFocus"
          :size="24"
          icon="md-checkmark-circle"
          type="warning"
          @click="completeCount"
          :disabled="endBtnDisable"
          >结束</Button
        >
      </div>
    </div>
  </Circle>
  <XSearch v-model="searchShow" :onSelected="onSelected" />
</template>
<script>
import XSearch from "@/components/XSearch";
// import { $setInterval } from "@/common/util";
import createWorker from "@/common/timerManager";
import { absorbedAdd } from "@/common/api";
export default {
  components: {
    XSearch,
  },
  props: {
    reflashList: {
      type: Function,
    },
  },
  data() {
    return {
      timeValue: "00:10:00",
      baseTime: 10 * 60 * 1000,
      targetTime: 10 * 60 * 1000,
      taskList: [],
      searchShow: false,
      timer: null,
      isFocus: false,
      isPause: false,
      isCountDown: false,
      intervalList: [], // 间隔列表
      elapsedTime: 0, // 记录定时器已运行的时间
      worker: null, // Worker 实例
      endBtnDisable: false,
    };
  },
  mounted() {},
  computed: {
    percent() {
      let timer = 100 - (this.targetTime / this.baseTime) * 100;
      return Math.floor(timer);
    },
    countDown() {
      return this.formatTime(this.targetTime);
    },
  },
  methods: {
    startTimer(cb, delay) {
      if (!this.worker) {
        this.worker = createWorker();
        this.worker.postMessage({
          action: "start",
          interval: delay,
        });
        this.worker.onmessage = (event) => {
          this.elapsedTime = event.data;
          cb();
        };
      }
    },

    // 停止定时器
    stopTimer() {
      if (this.worker) {
        this.worker.postMessage({ action: "stop" });
        this.worker.terminate(); // 终止 Worker，释放资源
        this.worker = null; // 清空 Worker 实例
      }
    },

    insertStartTime() {
      let nowTime = new Date().getTime();
      this.intervalList.push({
        startTime: nowTime,
        taskIds: this.taskList.map((e) => e.taskId),
        taskIdStrs: this.taskList.map((e) => e.taskIdStr),
        userId: this.$store.state.user.userId,
      });
    },
    insertEndTime() {
      let nowTime = new Date().getTime();
      if (this.intervalList.length > 0) {
        let obj = this.intervalList[this.intervalList.length - 1];
        if (!obj.endTime) obj.endTime = nowTime;
      }
    },
    async intervalEnd() {
      let record = 0;
      if (this.isCountDown) {
        record = (this.baseTime - this.targetTime) / 1000;
      } else {
        record = this.targetTime / 1000;
      }
      if (record < 10) {
        this.$Message.info("时间小于一分钟，已忽略本次专注");
      } else {
        this.insertEndTime();
        let startTime = 0;
        let endTime = 0;
        let totalTime = 0;
        for (let i = 0; i < this.intervalList.length; i++) {
          const element = this.intervalList[i];
          // element.taskIds = this.taskList.map((e) => e.taskIdStr);
          // element.taskIdStrs = this.taskList.map((e) => e.taskIdStr);
          if (i == 0) startTime = element.startTime;
          if (i == this.intervalList.length - 1) endTime = element.endTime;
          totalTime += element.endTime - element.startTime;
        }
        let updateTime = endTime;
        let params = {
          absorbedInfoList: this.intervalList,
          complete: false,
          completeIds: [],
          completeIdStrs: [],
          endTime,
          mode: this.isCountDown ? 2 : 1,
          startTime,
          totalTime,
          updateTime,
        };
        await absorbedAdd(params);
        this.$Message.success("恭喜，已完成本次专注");
        await this.reflashList();
      }
      this.$nextTick(() => {
        this.timeValue = "00:10:00";
        // this.timeChange("00:10:00");
        // this.baseTime = 10 * 60 * 1000;
        // this.targetTime = 10 * 60 * 1000;
        this.isFocus = false;
        this.isPause = false;
        this.taskList = [];
        this.intervalList = [];
        this.stopTimer();
        console.log(this.timeValue);
      });

      console.log(333333);
    },
    async completeCount() {
      this.endBtnDisable = true;
      await this.intervalEnd();
      this.endBtnDisable = false;
    },
    continueCount() {
      this.isPause = false;
      this.start();
    },
    pauseCount() {
      this.isPause = true;
      this.insertEndTime();
      // this.timer.clear();
      this.stopTimer();
    },
    startCount(type) {
      this.isCountDown = type;
      if (type && this.targetTime === 0) {
        this.$Message.warning("请先设置专注时间");
        return false;
      }
      this.isFocus = true;
      if (!this.isCountDown) {
        this.timeChange("00:00:00");
      }
      if (!this.isCountDown) {
        this.targetTime += 1000;
      } else {
        this.targetTime -= 1000;
      }
      this.start();
    },
    timeToMilliseconds(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return (hours * 3600 + minutes * 60 + seconds) * 1000;
    },
    timeChange(e) {
      if (!e) {
        e = "00:00:00";
      }
      console.log(e);
      this.baseTime = this.timeToMilliseconds(e);
      this.targetTime = this.baseTime;
    },
    start() {
      this.insertStartTime();
      this.startTimer(() => {
        if (this.isCountDown && this.targetTime <= 0) {
          // this.timer.clear();
          this.stopTimer();
          this.intervalEnd();

          return false;
        }
        if (!this.isCountDown) {
          this.targetTime += 1000;
        } else {
          this.targetTime -= 1000;
        }
      }, 1000);
    },
    formatTime(ms) {
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);

      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

      return formattedTime;
    },
    handleAdd() {
      this.searchShow = true;
    },
    handleClose(event, taskId) {
      console.log(event, taskId);
      this.taskList = this.taskList.filter((item) => item.taskId != taskId);
    },
    onSelected(taskList) {
      const mergedList = [...this.taskList, ...taskList];
      const uniqueList = Array.from(
        mergedList
          .reduce((map, item) => {
            if (!map.has(item.taskId)) {
              map.set(item.taskId, item);
            }
            return map;
          }, new Map())
          .values()
      );

      if (uniqueList.length > 10) {
        this.$Message.warning("任务最多关联10个");
        return false;
      }

      uniqueList.map((item) => {
        if (item.title?.length > 10) {
          item.subTitle = item.title.substring(0, 10) + "...";
        } else {
          item.subTitle = item.title;
        }
      });
      this.taskList = uniqueList;
    },
  },
};
</script>
<style lang="less" scoped>
.demo-Circle-custom {
  :deep(.ivu-input) {
    // border: none;
  }
}
.demo-Circle-custom h1 {
  color: #3f414d;
  font-size: 28px;
  font-weight: normal;
}
.demo-Circle-custom p {
  color: #657180;
  font-size: 14px;
  margin: 10px 0 15px;
}
.demo-Circle-custom span {
  display: block;
  padding-top: 15px;
  color: #657180;
  font-size: 14px;
}

.demo-Circle-custom span i {
  font-style: normal;
  color: #3f414d;
}
.task-tag {
  max-width: 220px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-list {
  margin: 0 10px;
}
</style>
