<template>
  <div class="view">
    <div class="header">
      <Space direction="vertical">
        <span style="font-size: 22px">番茄时钟</span>
      </Space>
      <span style="flex: 1"></span>
      <!-- <Space>
        <Dropdown trigger="click" placement="bottom-end">
          <Icon type="md-more" size="22" class="more-icon" />
          <template #list>
            <DropdownMenu class="dropdown-list">
              <DropdownItem @click="onMoreClick">
                {{ !showFlip ? "卡片形式" : "圆弧形式" }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </Space> -->
    </div>
    <div class="main">
      <div class="left">
        <!-- <XFlipClock v-if="showFlip" /> -->
        <XCircle v-if="!showFlip" :reflashList="reflashList" />
      </div>
      <div class="right">
        <div class="day-count">
          <p class="sub-title">
            <Icon type="md-done-all" :size="18" />专注信息
            <DatePicker
              size="small"
              type="daterange"
              :options="timeRange"
              @on-change="timeChange"
              placement="bottom-end"
              placeholder="请选择专注时间区间"
              style="margin-left: 10px; width: 250px"
            />
          </p>
          <div class="sub-wrapper">
            <p class="sub-content">
              专注<span class="highlight-num">{{
                absorbsDataList.length
              }}</span>
              <!-- <CountUp
                class="highlight-num"
                :end="absorbsDataList.length"
                :duration="2"
                ref="count"
              /> -->
              次
            </p>
            <p class="sub-content">共专注<span v-html="count"></span></p>
          </div>

          <!-- <Progress
            :percent="25"
            :stroke-width="5"
            :stroke-color="['#108ee9']"
          /> -->
        </div>
        <div class="content">
          <p class="sub-title"><Icon type="ios-list" :size="22" />专注记录</p>
          <div class="sub-wrapper">
            <TimelineItem
              color="green"
              v-for="item in absorbsDataList"
              :key="item.absorbedId"
              v-show="absorbsDataList.length"
            >
              <p class="range-content" v-html="item.dateRange"></p>
              <Tag color="success">{{ item.timeRange }}</Tag>
              <Tag color="success">{{ item.mode === 2 ? "倒" : "正" }}</Tag>
              <div class="icon-btn">
                <Icon
                  type="md-trash"
                  class="delete"
                  title="删除专注记录"
                  @click="deleteAbsorb(item)"
                />
              </div>
              <div
                v-for="(subItem, index) in item.absorbedInfoList"
                :key="index"
                class="absorbed-list"
              >
                <Tag color="success">{{ subItem.timeRange }}</Tag>
                <Icon
                  @click="editAbsorb(item, subItem, index)"
                  type="md-clipboard"
                  class="edit"
                  title="编辑专注记录"
                />
                <div
                  v-for="ssb in subItem.taskList"
                  :key="ssb.taskId"
                  style="opacity: 0.7"
                >
                  <Icon
                    type="md-checkbox-outline"
                    v-show="ssb.taskType === 0"
                  />
                  <Icon type="ios-paper-outline" v-show="ssb.taskType === 1" />
                  <Icon
                    type="md-checkmark-circle-outline"
                    v-show="ssb.taskType === 2"
                  />
                  {{ ssb.title }}
                </div>
              </div>
            </TimelineItem>
            <div class="default-icon" v-show="!absorbsDataList.length">
              <Icon type="ios-paper-outline" :size="32" />
              <p>暂无专注记录</p>
            </div>
          </div>
        </div>
        <!-- <div class="chart">
          <p class="sub-title">
            <Icon type="ios-pie-outline" :size="18" />专注分布
          </p>
        </div> -->
      </div>
    </div>
    <Modal
      draggable
      sticky
      scrollable
      :mask="true"
      v-model="showModal"
      title="专注任务编辑"
      @on-ok="updateAbsorbed"
      @on-cancel="cancel"
    >
      <Tag
        style="margin: 5px"
        :title="item.title"
        v-for="(item, index) in taskList"
        :key="item.taskId"
        :name="item.taskId"
        :closable="isPause || !isFocus"
        @on-close="handleClose"
        >{{ index + 1 }}.{{ item.title }}</Tag
      >
      <Button
        style="margin: 5px"
        icon="ios-add"
        type="success"
        size="small"
        @click="handleAdd"
        ghost
        >添加任务</Button
      >
    </Modal>
  </div>
  <XSearch v-model="searchShow" :onSelected="onSelected" />
</template>

<script>
// import XFlipClock from "@/components/XFlipClock";
import XSearch from "@/components/XSearch";
import XCircle from "@/components/XCircle";
import {
  absorbsList,
  taskList,
  absorbedDel,
  absorbedUpdate,
} from "@/common/api";
export default {
  components: {
    // XFlipClock,
    XCircle,
    XSearch,
  },
  async mounted() {
    this.tasks = await taskList();
    this.absorbs = await absorbsList();
    this.dataAssemble();
  },
  data() {
    return {
      showFlip: false,
      timeRange: {
        shortcuts: [
          {
            text: "今天",
            value() {
              const start = new Date();
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "本周",
            value() {
              const start = new Date();
              const end = new Date();

              const today = start.getDay();
              const diffToMonday =
                start.getDate() - today + (today === 0 ? -6 : 1);

              start.setDate(diffToMonday);
              start.setHours(0, 0, 0, 0);

              end.setDate(diffToMonday + 6);
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "本月",
            value() {
              const start = new Date();
              start.setDate(1);
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setMonth(end.getMonth() + 1);
              end.setDate(0);
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "近一个月",
            value() {
              const start = new Date();
              const end = new Date();
              end.setMonth(end.getMonth() + 1);
              end.setHours(23, 59, 59, 999);
              return [start, end];
            },
          },
          {
            text: "今年",
            value() {
              const start = new Date();
              start.setMonth(0, 1);
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setMonth(11, 31);
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "近一年",
            value() {
              const start = new Date();
              start.setFullYear(start.getFullYear() - 1);
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "去年",
            value() {
              const start = new Date();
              start.setFullYear(start.getFullYear() - 1, 0, 1); // 设置为去年的1月1日
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setFullYear(end.getFullYear() - 1, 11, 31); // 设置为去年的12月31日
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
          {
            text: "近三年",
            value() {
              const start = new Date();
              start.setFullYear(start.getFullYear() - 3);
              start.setHours(0, 0, 0, 0);

              const end = new Date();
              end.setHours(23, 59, 59, 999);

              return [start, end];
            },
          },
        ],
      },
      absorbs: [],
      tasks: [],
      absorbsDataList: [],
      absorbsDataListBase: [],
      count: 0,
      timeString: "",
      timeRangeTmp: [null, null],
      showModal: false,
      searchShow: false,
      taskList: [],
      currentAbs: {},
    };
  },
  methods: {
    async reflashList() {
      this.absorbs = await absorbsList();
      this.dataAssemble();
    },
    timeChange(e) {
      const [start, end] = e;
      if (start && end) {
        this.timeRangeTmp = [start, end];
        const startTime = this.getTimeStamp(start, true);
        const endTime = this.getTimeStamp(end, false);
        this.absorbsDataList = this.filterByTimeRange(
          this.absorbsDataListBase,
          startTime,
          endTime
        );
      } else {
        this.absorbsDataList = this.absorbsDataListBase;
      }
      this.getTimeAndCount();
    },
    filterByTimeRange(objects, startTime, endTime) {
      return objects.filter((item) => {
        return item.updateTime >= startTime && item.updateTime <= endTime;
      });
    },
    formatDuration(ms) {
      if (ms < 1000) {
        return `${ms}毫秒`;
      }

      const years = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));
      ms %= 1000 * 60 * 60 * 24 * 365;
      const months = Math.floor(ms / (1000 * 60 * 60 * 24 * 30));
      ms %= 1000 * 60 * 60 * 24 * 30;
      const days = Math.floor(ms / (1000 * 60 * 60 * 24));
      ms %= 1000 * 60 * 60 * 24;
      const hours = Math.floor(ms / (1000 * 60 * 60));
      ms %= 1000 * 60 * 60;
      const minutes = Math.floor(ms / (1000 * 60));
      ms %= 1000 * 60;
      const seconds = Math.floor(ms / 1000);

      let result = "";
      if (years > 0) result += `${years}年 `;
      if (months > 0) result += `${months}月 `;
      if (days > 0) result += `${days}天 `;
      if (hours > 0) result += `${hours}小时 `;
      if (minutes > 0) result += `${minutes}分钟 `;
      if (seconds > 0) result += `${seconds}秒`;

      return result.trim() || "0秒";
    },
    getTimeAndCount() {
      let count = 0;

      this.absorbsDataList.forEach((item) => {
        if (item.absorbedInfoList?.length) {
          item.absorbedInfoList.forEach((info) => {
            if (info.endTime > 0 && info.startTime > 0) {
              count += info.endTime - info.startTime;
            }
          });
        }
      });
      const htmlText = this.formatDuration(count);
      this.count = htmlText.replace(
        /(\d+)/g,
        '<span class="highlight-num">$1</span>'
      );
    },
    async dataAssemble() {
      let absorbs = this.absorbs;
      absorbs = absorbs.filter((item) => {
        return item.startTime > 0 && item.endTime > 0;
      });
      const tasksMap = new Map(this.tasks.map((task) => [task.taskId, task]));
      absorbs.forEach((item) => {
        const htmlText = this.formatTimestamp(item.updateTime);
        if (item.endTime > 0 && item.startTime > 0) {
          // let count = 0;
          // count += item.endTime - item.startTime;
          item.timeRange =
            this.formatTimeRange(item.startTime, item.endTime) +
            ` (${this.formatDuration(item.totalTime)})`;
        }
        item.dateRange = htmlText.replace(
          /(\d+)/g,
          '<span class="day-highlight-num">$1</span>'
        );

        if (item.absorbedInfoList?.length) {
          item.absorbedInfoList.forEach((info) => {
            info.taskList = [];
            info.totalTime = info.endTime - info.startTime;
            info.timeRange =
              this.formatTimeRange(info.startTime, info.endTime) +
              ` (${this.formatDuration(info.totalTime)})`;
            if (info.taskIds?.length) {
              const taskList = info.taskIds
                .map((taskId) => tasksMap.get(taskId))
                .filter((task) => task !== undefined);

              info.taskList.push(...taskList);
            }
          });
        }
      });

      // console.log(absorbs);
      this.absorbsDataList = absorbs.reverse();
      this.absorbsDataListBase = absorbs;
      this.getTimeAndCount();
    },
    formatTimeRange(startTimestamp, endTimestamp) {
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);
      const startHour = startDate.getHours().toString().padStart(2, "0");
      const startMinute = startDate.getMinutes().toString().padStart(2, "0");
      const endHour = endDate.getHours().toString().padStart(2, "0");
      const endMinute = endDate.getMinutes().toString().padStart(2, "0");
      return `${startHour}:${startMinute}~${endHour}:${endMinute}`;
    },
    getTimeStamp(dateStr, isStartTime) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      let resultDate;
      if (isStartTime) {
        resultDate = new Date(year, month, day, 0, 0, 0, 0);
      } else {
        resultDate = new Date(year, month, day, 23, 59, 59, 999);
      }

      return resultDate.getTime();
    },
    deleteAbsorb(absorbed) {
      this.$Modal.confirm({
        title: "删除确认",
        content: "是否删除专注记录",
        loading: true,
        onOk: async () => {
          const absorbedId = absorbed.absorbedId;
          await absorbedDel({ absorbedId });
          this.absorbs = this.absorbs.filter(
            (item) => item.absorbedId !== absorbed.absorbedId
          );
          this.dataAssemble();
          this.timeChange(this.timeRangeTmp);
          this.$Modal.remove();
          this.$Message.success("删除成功");
        },
      });
    },
    editAbsorb(absorbed, subItem, index) {
      absorbed.currentIndex = index;
      this.taskList = subItem.taskList;
      this.currentAbs = absorbed;
      this.showModal = true;
    },
    async updateAbsorbed() {
      const absorbedId = this.currentAbs.absorbedId;
      let absorbedInfoList = [];
      if (this.currentAbs.absorbedInfoList) {
        absorbedInfoList = this.currentAbs.absorbedInfoList.map(
          (item, index) => {
            let { startTime, taskIds, taskIdStrs, endTime, userId } = item;
            if (index === this.currentAbs.currentIndex) {
              taskIds = this.taskList.map((item) => item.taskId);
              taskIdStrs = this.taskList.map((item) => item.taskIdStr);
              userId = this.$store.state.user.userId;
            }
            return { startTime, taskIds, taskIdStrs, endTime, userId };
          }
        );
      }
      await absorbedUpdate({ absorbedId, absorbedInfoList });
      await this.reflashList();
    },
    onMoreClick() {
      this.showFlip = !this.showFlip;
    },
    formatTimestamp(timestamp) {
      const inputDate = new Date(timestamp);
      const currentDate = new Date();

      const inputYear = inputDate.getFullYear();
      const inputMonth = inputDate.getMonth() + 1;
      const inputDay = inputDate.getDate();

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      if (
        inputYear === currentYear &&
        inputMonth === currentMonth &&
        inputDay === currentDay
      ) {
        return `${inputDay}日`;
      }

      if (inputYear === currentYear && inputMonth === currentMonth) {
        return `${inputDay}日`;
      }

      if (inputYear !== currentYear) {
        return `${inputYear}年${inputMonth}月${inputDay}日`;
      }

      return `${inputMonth}月${inputDay}日`;
    },
    onSelected(taskList) {
      const mergedList = [...this.taskList, ...taskList];
      const uniqueList = Array.from(
        mergedList
          .reduce((map, item) => {
            if (!map.has(item.taskId)) {
              map.set(item.taskId, item);
            }
            return map;
          }, new Map())
          .values()
      );

      if (uniqueList.length > 10) {
        this.$Message.warning("任务最多关联10个");
        return false;
      }

      uniqueList.map((item) => {
        if (item.title?.length > 10) {
          item.subTitle = item.title.substring(0, 10) + "...";
        } else {
          item.subTitle = item.title;
        }
      });
      this.taskList = uniqueList;
    },
    handleClose(event, taskId) {
      this.taskList = this.taskList.filter((item) => item.taskId != taskId);
    },
    handleAdd() {
      this.searchShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  width: 100%;
  background-color: var(--vp-c-bg-alt);
  color: var(--vp-c-text-1);
  display: flex;
  flex-direction: column;
  :deep(.ivu-input) {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--vp-c-divider);
    background-color: #ffffff00;
    outline: none;
  }

  .header {
    padding: 20px;
    display: flex;
    background-color: var(--vp-c-bg-elv);
    height: 70px;
  }
  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(100% - 70px);
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .right {
      width: 420px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
    }
    .day-count {
      height: 137px;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      :deep(.ivu-progress-inner) {
        background-color: #9494942e;
      }
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: flex-end;
        gap: 5px;
      }
      .sub-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        .sub-content {
          font-size: 12px;
          font-weight: 100;
          margin-right: 20px;
          &:last-child {
            margin-right: 0px;
          }
          :deep(.highlight-num) {
            font-size: 42px;
            color: #3d87da;
          }
        }
      }
    }
    .content {
      flex-grow: 1;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      overflow: hidden;
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      :deep(.ivu-tag) {
        background: #3d87da26;
        border-color: var(--vp-c-divider);
        .ivu-tag-text {
          color: var(--vp-c-text-1) !important;
        }
        .ivu-icon-ios-close {
          color: var(--vp-c-text-1);
        }
      }
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .sub-wrapper {
        height: 100%;
        padding: 10px 0px 0 10px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        .range-content {
          font-size: 12px;
          display: inline-block;
          margin-right: 10px;
        }
        .absorbed-list {
          margin: 10px;
          background-color: rgb(140 140 140 / 14%);
          border-radius: 4px;
          padding: 5px 10px;
        }
        :deep(.ivu-timeline-item-head) {
          background-color: var(--vp-c-bg);
        }
        :deep(.ivu-timeline-item-tail) {
          border-color: var(--vp-c-divider);
        }
        :deep(.day-highlight-num) {
          font-size: 22px;
          color: #3d87da;
          font-weight: 100;
          margin: 0 3px;
        }
        :deep(.ivu-timeline-item-content) {
          top: -10px;
        }
        .default-icon {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 10vh;
          color: var(--vp-c-text-2);
          p {
            font-size: 14px;
            margin-top: 10px;
          }
        }
        .icon-btn {
          display: inline-block;
          vertical-align: bottom;
          .delete {
            color: var(--el-color-danger-dark-2);
            font-size: 18px;
            line-height: 18px;
            cursor: pointer;
            &:hover {
              transform: scale(1.3);
            }
          }
        }
        .edit {
          color: var(--el-color-primary-dark-2);
          font-size: 18px;
          line-height: 18px;
          cursor: pointer;
          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
    .chart {
      height: 400px;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
</style>
