<template>
  <div v-if="(Object.keys(editData).length==0)"></div>
  <Modal
    v-else
    class="drag-modal"
    draggable
    sticky
    scrollable
    :title="editData.attachType == 2? '习惯开始时间':'时间&重复'"
    :mask="true"
    :width="420"
    v-model="showModal"
    @on-visible-change="onModalChanged"
    @on-ok="onModalOk"
    @on-cancel="onModalCancel">
    <p style="margin-bottom: 12px;"></p>
    <el-config-provider :locale="locale">
      <el-date-picker
        style="height: 40px; width: 100%; margin-bottom: 12px;"
        v-model="editData.startTime"
        type="datetime"
        format="YYYY-MM-DD HH:mm"
        value-format="x"
        placeholder="开始时间"
        :disabled-date="startTimeDisable">
      </el-date-picker>
      <el-date-picker
        v-if="editData.attachType != 2"
        style="height: 40px; width: 100%;"
        v-model="editData.endTime"
        type="datetime"
        format="YYYY-MM-DD HH:mm"
        value-format="x"
        placeholder="结束时间"
        :disabled-date="endTimeDisable">
      </el-date-picker>
    </el-config-provider>
    <Poptip placement="right" :width="200" trigger="hover">
      <div style="margin-top: 12px;" class="space-between edit-cell hand">
        <span style="display: flex; align-items: center;">
          <XIcon style="margin-right: 5px;" size="16" iconClass="task_alert" />
          提醒
        </span>
        <span v-if="editData.notices.length > 0">
          <Text
            style="margin-right: 6px; max-width: 140px;"
            ellipsis
            :ellipsis-config="{ tooltip: true }">
            <span class="primary-color">{{ showNotice }}</span>
          </Text>
          <Icon type="ios-close-circle" class="primary-color" @click.stop="editData.notices = []" style="font-size: 18px;"/>
        </span>
        <span v-else>
          <span style="font-szie: 12px; margin-right: 6px;">无</span>
          <Icon type="ios-arrow-forward" style="font-size: 18px;"/>
        </span>
      </div>
      <template #content>
        <XNoticeTip :type="noticeType" v-model="editData.notices"></XNoticeTip>
      </template>
    </Poptip>
    <Poptip placement="right" :width="300" trigger="hover" v-if="showNotice">
      <div class="space-between edit-cell hand">
        <span style="display: flex; align-items: center;">
          <XIcon style="margin-right: 5px;" size="16" iconClass="alert" />
          提醒方式
        </span>
        <span v-if="showNoticeWay">
          <Text
              style="margin-right: 6px; max-width: 140px;"
              ellipsis
              :ellipsis-config="{ tooltip: true }">
            <span class="primary-color">{{ showNoticeWay }}</span>
          </Text>
          <Icon type="ios-close-circle" class="primary-color" @click.stop="editData.noticeInfo = noticeBase" style="font-size: 18px;"/>
        </span>
        <span v-else>
          <span style="font-szie: 12px; margin-right: 6px;">无</span>
          <Icon type="ios-arrow-forward" style="font-size: 18px;"/>
        </span>
      </div>
      <template #content>
        <XNoticeWayTip v-model="editData.noticeInfo"></XNoticeWayTip>
      </template>
    </Poptip>
    <Poptip placement="right" :width="200" trigger="hover">
      <div class="space-between edit-cell hand">
        <span style="display: flex; align-items: center;">
          <XIcon style="margin-right: 5px;" size="16" iconClass="task_cyclic" />
          {{editData.attachType == 2? '频率':'重复'}}
        </span>
        <span v-if="Object.keys(editData.repeat).length > 0">
          <Text
            style="margin-right: 6px; max-width: 160px;"
            ellipsis
            :ellipsis-config="{ tooltip: true }"
            class="primary-color">
            <span class="primary-color">{{ editData.repeat.msg }}</span>
          </Text>
          <Icon type="ios-close-circle" class="primary-color" v-if="editData.attachType!=2" @click.stop="editData.repeat = {}" style="font-size: 18px;"/>
          <Icon type="ios-arrow-forward" v-else style="font-size: 18px;"/>
        </span>
        <span v-else>
          <span style="font-szie: 12px; margin-right: 6px;">无</span>
          <Icon type="ios-arrow-forward" style="font-size: 18px;"/>
        </span>
      </div>
      <template #content>
        <XRepeatTip :startTime="editData.startTime" v-model="editData.repeat" :type="editData.attachType"></XRepeatTip>
      </template>
    </Poptip>
    <Poptip v-if="Object.keys(editData.repeat).length && editData.repeat.startMode!=3" placement="right" :width="200" trigger="hover">
      <div class="space-between edit-cell hand">
        <span style="display: flex; align-items: center;">
          <XIcon style="margin-right: 5px;" size="16" iconClass="task_cyclic" />
          {{editData.attachType == 2? '结束习惯':'结束重复'}}
        </span>
        <span>
          <Text
            style="margin-right: 6px; max-width: 140px;"
            ellipsis
            :ellipsis-config="{ tooltip: true }"
            class="primary-color">
            <span class="primary-color">
              <template v-if="editData.repeat.endMode==0">永不结束</template>
              <template v-else-if="editData.repeat.endMode==1">{{editData.attachType == 2? '打卡':'重复'}}{{ editData.repeat.repeatEndTimes || 1 }}{{editData.attachType == 2? '天':'次'}}后结束</template>
              <template v-else-if="editData.repeat.endMode==2">{{ showEndDate }}后结束</template>
              <template v-else-if="editData.repeat.endMode==3">{{ editData.repeat.repeatEndHabitTimes || 1 }}{{editData.attachType == 2? '次':'次'}}后结束</template>
            </span>
          </Text>
          <Icon type="ios-arrow-forward" style="font-size: 18px;"/>
        </span>
      </div>
      <template #content>
        <CellGroup>
          <Cell title="永不结束" @click="editData.repeat.endMode=0">
            <template #icon>
              <Icon
                :type="editData.repeat.endMode==0 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                class="primary-color"
                style="font-size: 20px;"
              />
            </template>
          </Cell>
          <Cell :title="editData.attachType == 2? '按天数结束':'按次数结束'" @click="onCountCellClick">
            <template #icon>
              <Icon
                :type="editData.repeat.endMode==1 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                class="primary-color"
                style="font-size: 20px;"
              />
            </template>
          </Cell>
          <div v-if="editData.repeat.endMode==1" style="display: flex; align-items: center; margin: 10px 20px; justify-content: space-around;">
            {{editData.attachType == 2? '打卡':'重复'}}
            <InputNumber style="width: 50px;" :max="100" :min="1" v-model="editData.repeat.repeatEndTimes" />
            {{editData.attachType == 2? '天':'次'}}
          </div>
          <Cell title="按时间结束" @click="onDateCellClick">
            <template #icon>
              <Icon
                :type="editData.repeat.endMode==2 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                class="primary-color"
                style="font-size: 20px;"
              />
            </template>
          </Cell>
          <el-config-provider :locale="locale">
            <el-date-picker
              v-if="editData.repeat.endMode==2"
              :teleported="false"
              style="height: 40px; width: calc(100% - 40px); margin: 10px 20px;"
              v-model="editData.repeat.repeatEndDate"
              type="date"
              value-format="x"
              placeholder="结束时间"
              :clearable="false">
            </el-date-picker>
          </el-config-provider>
          <Cell :title="`按打卡数量结束`" @click="onAllCountCellClick" v-if="editData.attachType == 2">
            <template #icon>
              <Icon
                :type="editData.repeat.endMode==3 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                class="primary-color"
                style="font-size: 20x;"
              />
            </template>
          </Cell>
          <div v-if="editData.repeat.endMode==3" style="display: flex; align-items: center; margin: 10px 20px; justify-content: space-around;">
            目标数量:
            <InputNumber style="width: 50px;" :max="100" :min="1" v-model="editData.repeat.repeatEndHabitTimes" />
          </div>
        </CellGroup>
      </template>
    </Poptip>

    <Poptip v-if="editData.attachType == 2" placement="right" :width="240" trigger="hover">
      <div class="space-between edit-cell hand">
        <span style="display: flex; align-items: center;">
          <Icon type="ios-medal-outline" style="margin-right: 5px;" size="16"/>
          配置
        </span>
        <span>
          <Text
              style="margin-right: 6px; max-width: 140px;"
              ellipsis
              :ellipsis-config="{ tooltip: true }"
              class="primary-color">
            <span class="primary-color">
              {{ editData.habit.habitDayNum }}{{editData.habit.habitUnit}}{{editData.repeat.msg!='随时打卡'?'/天':''}}
            </span>
          </Text>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #content>
        <CellGroup>
          <div style="display: flex; align-items: center; justify-content: space-between;padding: 5px 15px; " v-if="editData.repeat.msg!='随时打卡'">
            <div>
              每天完成
              <div style="font-size: 10px">每天计划完成的总量</div>
            </div>
            <InputNumber style="width: 50px;" :max="100" :min="1" v-model="editData.habit.habitDayNum" />
          </div>
          <Poptip placement="right" :width="200" trigger="hover">
            <div style="width: auto;padding: 5px 15px" class="space-between edit-cell hand" >
              <span style="display: flex; align-items: center;">
                计量单位
              </span>
              <span v-if="editData.habit.habitUnit">
                <Text
                    style="margin-right: 6px; max-width: 140px;"
                    ellipsis
                    :ellipsis-config="{ tooltip: true }">
                  <span class="primary-color">{{ editData.habit.habitUnit }}</span>
                </Text>
                <Icon type="ios-close-circle" class="primary-color" v-if="editData.attachType!=2" @click.stop="editData.habit.habitUnit = ''" />
                <Icon type="ios-arrow-forward" v-else/>
              </span>
              <span v-else>
                <span style="font-szie: 12px; margin-right: 6px;">无</span>
                <Icon type="ios-arrow-forward" />
              </span>
            </div>
            <template #content>
              <XUnitTip v-model="editData.habit.habitUnit"></XUnitTip>
            </template>
          </Poptip>
          <div></div>
          <Poptip placement="right" :width="340" trigger="hover">
            <div class="space-between edit-cell hand" style="width: auto;padding: 5px 15px">
              <span style="display: flex; align-items: center;">
                打卡方式
              </span>
              <span>
                <Text
                    style="margin-right: 6px; max-width: 140px;"
                    ellipsis
                    :ellipsis-config="{ tooltip: true }"
                    class="primary-color">
                  <span class="primary-color" v-if="editData.habit.habitType == 0">
                    自动打卡
                  </span>
                  <span class="primary-color" v-if="editData.habit.habitType == 1">
                    手动打卡
                  </span>
                  <span class="primary-color" v-if="editData.habit.habitType == 2">
                    全部完成
                  </span>
                </Text>
                <Icon type="ios-arrow-forward" />
              </span>
            </div>
            <template #content>
              <CellGroup>
                <Cell title="自动打卡(每次自动完成设定的打卡量)" @click="editData.habit.habitType=0">
                  <template #icon>
                    <Icon
                        :type="editData.habit.habitType==0 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                        class="primary-color"
                        style="font-size: 20px;"
                    />
                  </template>
                </Cell>
                <Cell title="手动打卡(每次手动输入打卡量)" @click="editData.habit.habitType=1">
                  <template #icon>
                    <Icon
                        :type="editData.habit.habitType==1 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                        class="primary-color"
                        style="font-size: 20px;"
                    />
                  </template>
                </Cell>
                <Cell title="全部完成(打卡一次即完成目标总量)" @click="editData.habit.habitType=2">
                  <template #icon>
                    <Icon
                        :type="editData.habit.habitType==2 ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                        class="primary-color"
                        style="font-size: 20px;"
                    />
                  </template>
                </Cell>
              </CellGroup>
            </template>
          </Poptip>
          <div style="display: flex; align-items: center; justify-content: space-between; padding: 5px 15px;" v-if="Object.keys(editData.habit).length && !editData.habit.habitType">
            <div>
              每次打卡完成量
            </div>
            <InputNumber style="width: 50px;" :max="100" :min="1" v-model="editData.habit.habitOnceNum" />
          </div>
        </CellGroup>
      </template>
    </Poptip>

    <div
        v-if="editData.attachType == 2"
        style="margin: 10px;"
        class="space-between"
    >
      <span>完成打卡后弹出打卡心得</span>
      <Switch v-model="editData.habit.autoShowLog"/>
    </div>
  </Modal>
</template>

<script>
import XNoticeTip from "@/components/XNoticeTip";
import XNoticeWayTip from "@/components/XNoticeWayTip";
import XRepeatTip from "@/components/XRepeatTip";
import "@/common/util";

import 'element-plus/es/components/date-picker/style/css';
import { ElDatePicker, ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn';
import XUnitTip from "@/components/XUnitTip";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    //editData.attachType 2是习惯 其他不是
  },
  components: {
    XUnitTip,
    ElDatePicker,
    ElConfigProvider,
    XNoticeTip,
    XRepeatTip,
    XNoticeWayTip
  },
  mounted() {
    this.initData();
  },
  data() {
    return {
      locale: zhCn,

      showModal: this.show,
      editData: {
      },
      noticeBase: "{\"appNotice\":false,\"emailAddress\":\"\",\"noticeWechat\":false}"
    }
  },
  computed: {
    showNotice() {
      if (this.editData.notices.length > 0) {
        let list = this.editData.notices.map(notice => notice.msg);
        return list.join(',');
      }
      return '';
    },
    showNoticeWay() {
      let arr = [];
      if (this.editData.noticeInfo){
        let obj = JSON.parse(this.editData.noticeInfo);
        Object.keys(obj).forEach(key=>{
          if(obj[key] == true || (key == 'emailAddress' && obj[key])){
            arr.push(key)
          }
        })
      }
      const dict = {
        appNotice:'应用',
        noticeWechat:'微信',
        emailAddress:'邮箱',
      }
      // console.log("zzzz",arr, this.modelValue)
      if (arr.length){
        return arr.map(item => dict[item]).join('、') + '提醒';
      }else{
        return '无';
      }
    },
    noticeType() {
      if (this.editData.startTime) {
        let zeroTime = new Date(this.editData.startTime).setHours(0, 0, 0, 0);
        return this.editData.startTime == zeroTime ? 0 : 1;
      }
      return 0;
    },
    showEndDate() {
      if (this.editData.repeat.endMode == 2) {
        let date = new Date().setHours(23, 59, 59, 999);
        if (this.editData.repeat.repeatEndDate) {
          date = new Date(this.editData.repeat.repeatEndDate);
        }
        return date.format("yyyy年MM月dd日");
      }
      return '';
    },
  },
  methods: {
    initData() {
      let baseData = {
        startTime: 0,
        endTime: 0,
        notices: [],
        repeat: {},
        habit:{},
        noticeInfo: this.noticeBase
      }
      // console.log(this.data )
      this.editData = Object.assign(baseData, this.data || {});
      if (!this.editData.habit) {
        this.editData.habit = {}
      }
      if (!this.editData.startTime) {
        this.editData.startTime = new Date().setHours(0,0,0,0);
      }
    },
    startTimeDisable() {
      return false;
      // return this.editData.endTime && date.getTime() > this.editData.endTime;
    },
    endTimeDisable() {
      return false;
      // return date.getTime() < this.editData.startTime;
    },
    onModalChanged(value) {
      value && this.initData();
      this.$emit('onModalChanged', value);
    },
    onModalOk() {
      if (this.editData.notices && this.editData.notices.length > 0) {
        for (let i = 0; i < this.editData.notices.length; i++) {
          const notice = this.editData.notices[i];
          if (notice.mode == 1) {
            notice.time = this.editData.startTime - 1000 * 60 * 60 * 24 * notice.day - 1000 * 60 * 60 * notice.hour - 1000 * 60 * notice.minute;
          } else if (notice.mode == 2) {
            let date = this.editData.startTime - 1000 * 60 * 60 * 24 * notice.day;
            notice.time = new Date(date).setHours(notice.hour, notice.minute, 0, 0);
          }
        }
      }
      if (!this.editData.startTime) {
        this.editData.startTime = new Date().setHours(0,0,0,0);
      }
      this.$emit('onModalOk', this.editData);
    },
    onModalCancel() {
      this.$emit('onModalCancel');
    },
    onCountCellClick() {
      if (!this.editData.repeat.repeatEndTimes) {
        this.editData.repeat.repeatEndTimes = 1;
      }
      this.editData.repeat.endMode = 1;
    },
    onDateCellClick() {
      if (!this.editData.repeat.repeatEndDate) {
        this.editData.repeat.repeatEndDate = new Date().setHours(23, 59, 59, 999);
      }
      this.editData.repeat.endMode = 2;
    },
    onAllCountCellClick() {
      if (!this.editData.repeat.repeatEndHabitTimes) {
        this.editData.repeat.repeatEndHabitTimes = 1;
      }
      this.editData.repeat.endMode = 3;
    },
  },
  watch: {
    show(value) {
      this.showModal = value;
    },
    data() {
      this.initData();
    },
    'editData.notices'(val) {
      if (val.length > 0) {
          let obj = JSON.parse(this.editData.noticeInfo);
          if (!obj.appNotice && !obj.emailAddress && !obj.noticeWechat) {
            obj.appNotice = true
          }
          this.editData.noticeInfo = JSON.stringify(obj)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.ivu-poptip-body){
    padding: 8px 0px;
  }
  :deep(.ivu-poptip) {
    width: 100% !important;
  }
  :deep(.ivu-poptip-rel) {
    width: 100%;
  }
  .edit-cell {
    width: 380px;
    height: 100%;
    line-height: 44px;
    padding: 0px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    &:hover {
      background: #F6F6F6;
    }
  }
</style>
