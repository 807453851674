<template>
  <div class="timer-component">
    <div class="sub-component">
      <span
        class="title no-drag"
        title="点击切换计时类型"
        @click="countTabToggle"
        >{{ title }}</span
      >
      <span class="time" v-show="countType === 1">{{ formatSeconds }}</span>
      <span class="time" v-show="countType === 2 && isCounting">{{
        formatSeconds
      }}</span>
      <span class="time-set" v-show="countType === 2 && !isCounting"
        ><Input
          clearable
          v-model="originMinute"
          number
          maxlength="3"
          type="number"
          class="no-drag count-input"
        />分钟
      </span>
      <span style="flex: 1"></span>
      <div style="display: flex; align-items: center">
        <div v-show="!isBegin" title="开始" style="margin-right: 28px">
          <p
            style="display: flex; align-items: center; cursor: pointer"
            @click="onBegin"
            class="no-drag"
          >
            <XIcon iconClass="play_begin" size="24" style="margin-right: 6px" />
            <span style="color: rgba(61, 135, 218, 1); font-size: 14px"
              >开始</span
            >
          </p>
        </div>
        <div
          v-show="isBegin"
          :title="isPause ? '继续' : '暂停'"
          style="margin-right: 12px"
          class="no-drag"
        >
          <XIcon
            :iconClass="isPause ? 'play_begin' : 'play_pause'"
            size="24"
            @click="pasuseClick"
          />
        </div>
        <div
          v-show="isBegin"
          title="结束"
          style="margin-right: 20px"
          class="no-drag"
        >
          <XIcon iconClass="play_done" size="24" @click="intervalEnd" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { absorbedAdd } from "@/common/api";
// import { $setInterval } from "@/common/util"
import createWorker from "@/common/timerManager";

export default {
  data() {
    return {
      tab: "normal",
      title: "正计时",
      countType: 1,
      isBegin: false,
      isPause: false,
      originSeconds: 0, // 初始的秒数
      seconds: 0,
      interval: {},
      intervalList: [], // 间隔列表
      isCounting: false,
      searchShow: false,
      taskList: [],
      originMinute: 0,
    };
  },
  mounted() {
    this.$Message.config({
      top: 10,
      duration: 2,
    });
  },
  computed: {
    formatSeconds() {
      let hours = Math.floor(this.seconds / 3600);
      let minutes = Math.floor((this.seconds % 3600) / 60);
      let seconds = this.seconds % 60;

      let showHours = hours < 10 ? "0" + hours : hours + "";
      let showMinutes = minutes < 10 ? "0" + minutes : minutes + "";
      let showSeconds = seconds < 10 ? "0" + seconds : seconds + "";
      if (hours == 0) {
        return `${showMinutes}:${showSeconds}`;
      } else {
        return `${showHours}:${showMinutes}:${showSeconds}`;
      }
    },
    relatedTitle() {
      if (this.taskList && this.taskList.length > 0) {
        let task = this.taskList[0];
        if (this.taskList.length == 1) {
          return task.title;
        } else {
          return task.title + `  等${this.taskList.length}条任务`;
        }
      }
      return "关联任务";
    },
  },
  methods: {
    countTabToggle() {
      if (this.isCounting) {
        return false;
      }
      if (this.countType === 1) {
        this.title = "倒计时";
        this.countType = 2;
      } else {
        this.title = "正计时";
        this.countType = 1;
      }
    },
    startTimer(cb, delay) {
      if (!this.worker) {
        this.worker = createWorker();
        this.worker.postMessage({
          action: "start",
          interval: delay,
        });
        this.worker.onmessage = (event) => {
          this.elapsedTime = event.data;
          cb();
        };
      }
    },
    stopTimer() {
      if (this.worker) {
        this.worker.postMessage({ action: "stop" });
        this.worker.terminate(); // 终止 Worker，释放资源
        this.worker = null; // 清空 Worker 实例
      }
    },
    onBegin() {
      if (this.countType === 1) {
        this.normalInterval();
      } else {
        if (this.originMinute == 0) return;
        this.seconds = this.originMinute * 60;
        this.reverseInterval();
      }
      this.isCounting = true;
      this.isBegin = true;
      this.insertStartTime();
    },
    normalInterval() {
      this.interval = this.startTimer(() => {
        this.seconds++;
      }, 1000);
    },
    reverseInterval() {
      this.interval = this.startTimer(() => {
        this.seconds--;
        if (this.seconds == 0) {
          this.intervalEnd();
        }
      }, 1000);
    },
    intervalEnd() {
      let record = 0;
      this.isCounting = false;
      if (this.countType === 1) {
        record = this.seconds;
      } else {
        record = this.originMinute * 60 - this.seconds;
      }
      if (record < 60) {
        this.$Message.info({
          content: "时间小于一分钟，已忽略本次专注",
        });
      } else {
        this.insertEndTime();
        let startTime = 0;
        let endTime = 0;
        let totalTime = 0;
        for (let i = 0; i < this.intervalList.length; i++) {
          const element = this.intervalList[i];
          element.taskIds = this.taskList.map((e) => e.taskIdStr);
          element.taskIdStrs = this.taskList.map((e) => e.taskIdStr);
          if (i == 0) startTime = element.startTime;
          if (i == this.intervalList.length - 1) endTime = element.endTime;
          totalTime += element.endTime - element.startTime;
        }
        let updateTime = endTime;
        let params = {
          absorbedInfoList: this.intervalList,
          complete: false,
          completeIds: [],
          completeIdStrs: [],
          endTime,
          mode: this.countType,
          startTime,
          totalTime,
          updateTime,
        };
        absorbedAdd(params).then((res) => {
          if (res) {
            this.$Message.success("恭喜，已完成本次专注");
          }
        });
      }
      this.seconds = 0;
      this.isBegin = false;
      this.isPause = false;
      this.intervalList = [];
      // this.interval.clear()
      this.stopTimer();
      // clearInterval(this.interval);
    },
    pasuseClick() {
      if (this.isPause) {
        this.isPause = false;
        this.insertStartTime();
        if (this.countType === 1) {
          this.normalInterval();
        } else {
          this.reverseInterval();
        }
        this.isBegin = true;
      } else {
        this.isPause = true;
        this.insertEndTime();
        // this.interval.clear()
        this.stopTimer();
        // clearInterval(this.interval);
      }
    },
    onSelected(taskList) {
      this.taskList = taskList;
    },
    onRelateClick() {
      if (!this.isBegin) {
        this.searchShow = true;
      }
    },
    // 点击开始或者重启添加开始时间
    insertStartTime() {
      let nowTime = new Date().getTime();
      this.intervalList.push({
        startTime: nowTime,
      });
    },
    insertEndTime() {
      let nowTime = new Date().getTime();
      if (this.intervalList.length > 0) {
        let obj = this.intervalList[this.intervalList.length - 1];
        if (!obj.endTime) obj.endTime = nowTime;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.timer-component {
  ::selection {
    background: transparent;
    color: inherit;
  }

  input::selection,
  textarea::selection {
    background: #66aaff;
    color: white;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-app-region: drag;
  .sub-component {
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: var(--vp-c-bg-elv);
    color: var(--vp-c-text-1);
    border-radius: 8px;
    border: 1px solid var(--vp-c-divider);
    display: flex;
    align-items: center;
    .title {
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
      margin-left: 16px;
      cursor: pointer;
    }
    .time {
      font-size: 16px;
      font-weight: 600;
      margin-left: 16px;
    }
    .time-set {
      margin-left: 16px;
      display: flex;
      align-items: center;
      .count-input {
        width: 60px;
        font-weight: 600;
        margin-right: 5px;
      }
    }
    .no-drag {
      -webkit-app-region: no-drag;
      display: flex;
      align-items: center;
    }
  }
}
</style>
