<template>
  <div class="login">
    <div class="content">
      <div class="form-bg">
        <div class="logo"></div>     
        <Form ref="form" :model="formdata" :rules="rules">
          <FormItem prop="email">
            <Input :border="false" size="large" type="email" v-model="formdata.email" placeholder="请输入邮箱"></Input>
          </FormItem>
          <FormItem v-if="(pageType==2)" prop="captcha">
            <Row>
              <Input style="flex: 1;" :border="false" size="large" type="text" v-model="formdata.captcha" placeholder="请输入验证码"></Input>
              <Button class="captcha-button" @click="captchaClick">{{ seconds == 60 ? '获取验证码' : seconds }}</Button>
            </Row>
          </FormItem>
          <FormItem prop="password">
            <Input :border="false" size="large" :type="showPassword ? 'text' : 'password'" v-model="formdata.password" placeholder="请输入密码" @keyup.enter="handleSubmit()">
              <template #suffix>
                <div class="hand" @click="(showPassword=!showPassword)">
                  <Icon v-if="showPassword" style="line-height: 48px; color: #000;" type="md-eye" />
                  <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
                </div>
              </template>
            </Input>
          </FormItem>
          <FormItem>
            <div v-if="(pageType==0)">
              <Button type="text" style="color: #BABABA" @click="onForget">忘记密码?</Button>
              <Button type="text" style="color: #3D87DA; float: right;" @click="onRegister">注册</Button>
            </div>
            <div v-else-if="(pageType==1)">
              <Button type="text" style="color: #3D87DA; float: right;" @click="onLogin">立即登录</Button>
            </div>
            <div v-else-if="(pageType==2)">
              <Button type="text" style="color: #BABABA" @click="onLogin">返回登录</Button>
              <Button type="text" style="color: #3D87DA; float: right;" @click="onRegister">注册</Button>
            </div>
          </FormItem>
          <FormItem>
            <Button 
              type="primary" 
              style="width: 100%; height: 48px; border-radius: 8px; font-size: 16px; box-shadow: 0px 4px 61px 0px rgba(94, 166, 224, 0.4);" 
              size="large" 
              :loading="logging"
              @click="handleSubmit">
              <template v-if="(pageType==0)">登录</template>
              <template v-else-if="(pageType==1)">注册</template>
              <template v-else-if="(pageType==2)">重置</template>
            </Button>
            <Checkbox v-if="(pageType==0)" v-model="remember" size="small" style="margin-top: 8px;">
              <span style="color: #BABABA;">记住我</span>
            </Checkbox>
            <div v-else style="margin-top: 41px;"></div>
          </FormItem>
          <FormItem>
            <div style="border-top: 1px solid #F2F2F2;"></div>
            <p style="margin: 18px 0 20px 0; font-size: 13px; color: #BABABA; text-align: center;">或者以下方式登录</p>
            <XThirdVerify></XThirdVerify>
          </FormItem>
        </Form>
      </div>      
    </div>
  </div>
</template>
<script>

import { 
  login, 
  register, 
  resetPassword,
  captcha,
  
} from '../common/api'
import XThirdVerify from '@/components/XThirdVerify.vue';
import { syncData, initInexDB } from "@/common/syncUntil";

export default {
  components: {
    XThirdVerify
  },
  mounted() {
    if (this.pageType == 0) {
      this.formdata.email = localStorage.getItem('login_account');
      this.formdata.password = localStorage.getItem('login_password');
    }
  },
  data() {
    return {
      logging: false,
      remember: true,
      showPassword: false,
      seconds: 60,
      formdata: {
        email: '',
        password: '',
        captcha: ''
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 6, message: '请输入六位以上的密码', trigger: 'blur' }
        ]
      },
    };
  },
  computed: {
    pageType() {
      switch (this.$route.name) {
        case 'login':
          return 0;
        case 'register':
          return 1;
        case 'reset':
          return 2;
        default:
          return 0;
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.logging = true
          if (this.pageType == 0) {
            login(this.formdata.email, this.formdata.password).then( async (res) => {
              if (res) {
                console.log(res)
                this.$store.commit("updateInfo", res);
                await initInexDB();
                await syncData();
                this.$router.push("/manager/work");
                
                localStorage.setItem('login_account', this.remember ? this.formdata.email : '');
                localStorage.setItem('login_password', this.remember ? this.formdata.password : '');

              } else {
                this.logging = false
              }
            });
          } else if (this.pageType == 1) {
            register(this.formdata.email, this.formdata.password).then( async res => {
              if (res) {
                this.$store.commit("updateInfo", res);
                await initInexDB();
                await syncData();
                this.$router.push("/manager/work");
              } else {
                this.logging = false
              }
            });
          } else if (this.pageType == 2) {
            resetPassword(this.formdata.mail, this.formdata.password, this.formdata.captcha).then(res => {
              this.logging = false
              if (res) {
                this.$router.push({name: 'login'});
              }
            });
          }
        }
      })
    },
    onLogin() {
      this.$router.push({ name: "login" });
    },
    onRegister() {
      this.$router.push({ name: "register" });
    },
    onForget() {
      this.$router.push({ name: "reset" });
    },
    captchaClick() {
      if (this.seconds < 60) return;
      this.$refs['form'].validateField('email', (valid) => {
        if (!valid) {
          captcha(this.formdata.email, 1);
          var interval = setInterval(() => {
            if (this.seconds == 1) {
              this.seconds = 60;
              clearInterval(interval);
            } else {
              this.seconds--;
            }
          }, 1000);
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
  .login {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content {
      background: url(../assets/login_bg.png) center center no-repeat;
      background-size: contain;
      margin: 0 auto;
      padding: 0px 20px;
      width: 100%;
      .form-bg {
        max-width: 400px;
        margin: 0 auto;
        padding: 50px;
        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        border-radius: 12px;
        .logo {
          margin: 0 auto;
          margin-bottom: 50px;
          background: url(../assets/logo1.png) center center no-repeat;
          background-size: contain;
          width: 90px;
          height: 90px;
        }
        :deep(.ivu-input) {
          border-radius: 8px;
          background-color: #F2F8FF;
          color: #333;
          height: 48px;
          padding: 0px 20px;
        }
        :deep(.ivu-form-item) {
          margin-bottom: 32px;
        }
        :deep(.ivu-input-suffix) {
          width: 48px;
        }
        .captcha-button {
          width: 102px;
          height: 48px; 
          margin-left: 10px; 
          border-radius: 8px; 
          background-color: #F2F8FF;
          color: #333;
        }
      }
    }
  }
</style>